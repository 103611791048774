import React from "react";
import Header from "./Header";
import Hook from "./Hook";
import ExampleCarousel from "./Carousel"
import Footer from "./Footer";
import ExampleAccordion from "./Accordion";
import ExampleGallery from "./Gallery";
import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {

  return (
    <div className="App">
      <Header />
      <Hook />
      <ExampleCarousel />
      <ExampleAccordion />
      <ExampleGallery />
      <Footer />
    </div>
  );
}

export default App;
