import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import TallPic1 from '../images/TallPic1.webp'
import WidePic1 from '../images/WidePic1.webp'
import "./Accordion.css";
import YoutubeEmbed from "./YoutubeEmbed";


function ExampleAccordion() {

    return (
        <Accordion defaultActiveKey="0" id='Accordion' flush>
            <Accordion.Item className='accordion-item-bg' eventKey="0">
                <Accordion.Header>Accordion Item #1</Accordion.Header>
                <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                    <img
                        className="d-block carousel-img"
                        src={TallPic1}
                        alt="First slide"
                        id="Carousel"
                    />
                </Accordion.Body>

            </Accordion.Item>
            <Accordion.Item className='accordion-item-bg' eventKey="1">
                <Accordion.Header>Accordion Item #2</Accordion.Header>
                <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                    <img
                        className="d-block carousel-img"
                        src={WidePic1}
                        alt="Second slide"
                    />
                </Accordion.Body>

            </Accordion.Item>
            <Accordion.Item className='accordion-item-bg' eventKey="2">
                <Accordion.Header>Accordion Item #3</Accordion.Header>
                <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                    <div className='accordian-video'>
                        <YoutubeEmbed embedId="6stlCkUDG_s" />
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

export default ExampleAccordion;