import React from "react";
import "./Hook.css";

function Hook() {


    return <h1 className="hook">
        Your hook line here
    </h1>
}

export default Hook;