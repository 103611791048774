import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import useMediaQuery from "@mui/material/useMediaQuery";
import GalleryImage from "./GalleryImage";
import "./Gallery.css";


export default function Gallery() {
    const large = useMediaQuery("(min-width:1200px)");
    const small = useMediaQuery("(max-width:500px)");

    return (
        <div className="gallery" id="Gallery">
            <h1>Gallery</h1>
            <ImageList
                variant="masonry"
                cols={large ? 3 : small ? 1 : 2}
                gap={8}
            >
                {itemData.map((item) => (
                    <ImageListItem key={item.img}>
                        <GalleryImage
                            src={`${item.img}`}
                            fullResolution={`${item.fullResolution}`}
                            alt={item.title}
                            loading="lazy"
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        </div>
    );
}

const itemData = [
    {
        img: "https://picsum.photos/id/123/640/480",
        fullResolution: "https://picsum.photos/id/123/1024/768",
        title: "Drops"
    },
    {
        img: "https://picsum.photos/id/321/480/640",
        fullResolution: "https://picsum.photos/id/321/768/1024",
        title: "Skyline"
    },
    {
        img: "https://picsum.photos/id/111/720/480",
        fullResolution: "https://picsum.photos/id/111/1280/720",
        title: "Car"
    },
    {
        img: "https://picsum.photos/id/222/480/720",
        fullResolution: "https://picsum.photos/id/222/720/1280",
        title: "Cloud"
    },
    {
        img: "https://picsum.photos/id/999/200",
        fullResolution: "https://picsum.photos/id/999/1000",
        title: "Fruit"
    }
];
