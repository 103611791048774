import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import TallPic1 from '../images/TallPic1.webp'
import WidePic1 from '../images/WidePic1.webp'
import YoutubeEmbed from "./YoutubeEmbed";
import './Carousel.css'


function ExampleCarousel() {

  return (
    <Carousel id='Carousel'>
      <Carousel.Item>
        <img
          className="d-block carousel-img transparent"
          src={TallPic1}
          alt="First slide"
        />
        <Carousel.Caption>
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block carousel-img transparent"
          src={WidePic1}
          alt="Second slide"
        />

        <Carousel.Caption>
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <div className='carousel-img transparent'>
          <YoutubeEmbed embedId="6stlCkUDG_s" />
        </div>
      </Carousel.Item>
    </Carousel>
  );
}

export default ExampleCarousel;