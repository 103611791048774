import * as React from "react";
import Dialog from "@mui/material/Dialog";
import "./GalleryImage.css";
import { useState } from "react";

function GalleryImage(props) {
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };

    return (
        <>
            <img
                src={props.src}
                className="gallery-image"
                alt={props.alt}
                onClick={handleClickOpen}
                loading="lazy"
            />
            <Dialog onClose={handleClose} open={open} PaperProps={{ className: "gallery-popup" }}>
                <img
                    src={props.fullResolution}
                    alt={props.alt}
                    onClick={handleClose}
                    loading="lazy"
                />
            </Dialog>
        </>
    );
}

export default GalleryImage;
