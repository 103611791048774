import * as React from "react"

const SvgComponent = (props) => (

  <svg width={16} height={16} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      style={{
        fill: "#00f",
        fillOpacity: 1,
        stroke: "#000",
        strokeWidth: 0.0249584,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
      }}
      d="m8 .512 2.161 4.974 2.954-2.954A7.488 7.488 0 0 0 8 .512Zm0 0L5.982 5.63 8 7.647l2.022-2.022Zm0 0a7.488 7.488 0 0 0-5.105 2.03L5.843 5.49Zm5.468 2.373v10.21A7.488 7.488 0 0 0 15.488 8c0-1.9-.723-3.728-2.02-5.115Zm-10.925.01A7.488 7.488 0 0 0 .513 8a7.487 7.487 0 0 0 2.03 5.104Zm10.572.343-2.74 2.74.77 1.772h0l1.97 4.532Zm-10.22.01v9.042l1.968-4.54.766-1.768Zm7.327 2.883-1.62 1.62h2.26Zm-4.44.005L5.147 7.75h2.251ZM4.95 8.25l-1.747 4.43 4.33-2.786Zm.974 0 2.084 1.338 2.08-1.338Zm5.136 0L8.483 9.893l4.325 2.777Zm-3.051 1.946-5.113 3.261A7.487 7.487 0 0 0 8 15.487a7.488 7.488 0 0 0 5.115-2.04Z"
    />
    <path
      style={{
        fill: "#000",
        stroke: "#000",
        strokeWidth: ".0249584px",
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeOpacity: 1,
      }}
      d="M13.115 2.532 8 7.647 2.895 2.543l-.352.352v10.21l.352.352V3.248L7.397 7.75l.603.5.603-.5 4.512-4.512v10.21l.353-.354V2.885Z"
    />
    <path
      style={{
        fill: "#000",
        stroke: "#000",
        strokeWidth: 0.0249584,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      d="M2.543 13.105 8 .512 5.146 7.75h5.716L8 .512l5.468 12.582-.353.353-2.056-5.197H4.95l-2.054 5.207-.352-.352z"
    />
    <path
      style={{
        fill: "#000",
        stroke: "#000",
        strokeWidth: ".0249584px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeOpacity: 1,
      }}
      d="m3.202 12.68 4.33-2.786L4.949 8.25h.974l2.084 1.338 2.08-1.338h.972L8.483 9.893l4.325 2.777.307.777L8 10.201l-5.105 3.256.307-.777z"
    />
  </svg>
)

export default SvgComponent